import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SmoothScroll from 'smooth-scroll';
import Rellax from 'rellax';
import "./css/global.css";
import "./css/header.css";
import "./css/footer.css";
import "./css/home.css";

var rellax = new Rellax('.rellax');

var scroll = new SmoothScroll('a[href*="#"]');

const bodyTag = document.body;
let header = document.querySelector('.header-container');
let computedStyle = getComputedStyle(header);
let headerBoxHeight = computedStyle.height
const mobileMenu = document.getElementById("mobile-menu");
const mobileMenuOpen = document.getElementById("mobile-menu-open");
const mobileMenuClose = document.getElementById("mobile-menu-close");
const siteCopyright = document.getElementById('copyright-date');

mobileMenuOpen.addEventListener('click', e => {
  disableBodyScroll(mobileMenu);
});

mobileMenuClose.addEventListener('click', e => {
  enableBodyScroll(mobileMenu);
});

var today = new Date();
var yyyy = today.getFullYear();

today = yyyy;
siteCopyright.innerText = today;

function init() {
  // A demo: add an element to the document, then announce it
  const alertNode = document.createElement("div");
  const mainNode = document.querySelector("main");

  alertNode.setAttribute("role", "status");
  alertNode.setAttribute("aria-live", "polite");
  mainNode.appendChild(alertNode);

  // Wait some arbitrary time, then populate it
  setTimeout(() => {
    const successNode = document.createElement("p");
    // Let's verify that Vite is injecting environment variables
    // @see https://vitejs.dev/guide/env-and-mode.html#env-variables
    if (import.meta.env.DEV === true) {
      successNode.innerText = "Vite is serving the script correctly!";
    }
    if (import.meta.env.PROD === true) {
      successNode.innerText =
        "";
    }
    alertNode.appendChild(successNode);
  }, 400);
}

init();